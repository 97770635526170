var exports = {};
!function (t, o) {
  exports = o();
}(exports, function () {
  return (() => {
    "use strict";

    var t = {
        d: (o, e) => {
          for (var n in e) t.o(e, n) && !t.o(o, n) && Object.defineProperty(o, n, {
            enumerable: !0,
            get: e[n]
          });
        },
        o: (t, o) => Object.prototype.hasOwnProperty.call(t, o),
        r: t => {
          "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
            value: "Module"
          }), Object.defineProperty(t, "__esModule", {
            value: !0
          });
        }
      },
      o = {};
    function e(t) {
      return e = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      }, e(t);
    }
    t.r(o), t.d(o, {
      default: () => a
    });
    var n = encodeURIComponent;
    function r(t, o, r, c, a) {
      var i = c && a.arrayPrefix || "";
      if ("object" === e(o)) {
        var f = "".concat(t).concat(i).concat(r && "]", "[");
        return "".concat(u(o, "".concat(r).concat(f), a));
      }
      return r && r.length ? "".concat(r).concat(t, "]").concat(i, "=").concat(n(o)) : "".concat(t).concat(i, "=").concat(n(o));
    }
    function c(t, o, e, n) {
      return o.map(function (o) {
        return r(t, o, e, !0, n);
      }).join("&");
    }
    function u(t) {
      var o = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "",
        e = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
      return (Array.isArray(t) ? t.map(function (t, n) {
        return r("".concat(n), t, o, !0, e);
      }) : Object.keys(t).filter(function (o) {
        return void 0 !== t[o];
      }).map(function (n) {
        return t[n] && Array.isArray(t[n]) ? c("".concat(n), t[n], o, e) : r(n, t[n], o, !1, e);
      })).join("&").replace(/%20/g, "+");
    }
    const a = u;
    return o;
  })();
});
export default exports;